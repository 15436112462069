import React from 'react';
import ReactDOM from 'react-dom';
import { xdai, dai, eth, usdc, ERC20Asset } from '@burner-wallet/assets';
import BurnerCore from '@burner-wallet/core';
import { InjectedSigner, LocalSigner } from '@burner-wallet/core/signers';
import { InfuraGateway, InjectedGateway, XDaiGateway, } from '@burner-wallet/core/gateways';
import Exchange, { Uniswap, XDaiBridge } from '@burner-wallet/exchange';
import ModernUI from '@burner-wallet/modern-ui';
// import CollectablePlugin from '@burner-factory/collectable-plugin';
import ENSPlugin from '@burner-wallet/ens-plugin';
// import LinkdropPlugin from '@linkdrop/burner-plugin';
// import OrderMenuPlugin from '@burner-factory/order-menu-plugin';

const maz = new ERC20Asset({
  id: 'maz',
  name: 'MAZ Token',
  network: '1',
  address: '0xceF66281B423071A123A4263F9c3dc188d04fC32',
  icon: './meaningwave-color.jpeg',
});

const xmaz = new ERC20Asset({
  id: 'xmaz',
  name: 'MAZ on xDai',
  network: '100',
  address: '0x8f875A1D0C129f326C41284265119cc25Ee81297',
  icon: './meaningwave-color.jpeg',
});

const core = new BurnerCore({
  signers: [new InjectedSigner(), new LocalSigner()],
  gateways: [
    new InjectedGateway(),
    new InfuraGateway(process.env.REACT_APP_INFURA_KEY),
    new XDaiGateway(),
  ],
  // TODO use Sai
  assets: [xmaz, maz, xdai, dai, eth, usdc],
});

const exchange = new Exchange({
  pairs: [new XDaiBridge(), new Uniswap('maz'), new Uniswap('dai')],
});

const theme = {accentColor:'#EA3EF0', logo:'./meaningwave-bw.png'};


const BurnerWallet = () =>
  <ModernUI
    title="Meaningwave Wallet"
    theme={theme}
    core={core}
    plugins={[exchange,
              // new CollectablePlugin('1', '0x495f947276749Ce646f68AC8c248420045cb7b5e'),
              // new CollectablePlugin('100', '0xcf964c89f509a8c0ac36391c5460df94b91daba5'),
              new ENSPlugin(),
              // new LinkdropPlugin(),
              // new OrderMenuPlugin("Song request", {
              //   factory: 'https://meaningwave.com',
              //   title: 'Request a song',
              //   description: 'Donate tokens to order a song',
              //   icon: null,
              //   hideButton: false,}),

              ]}
  />


ReactDOM.render(<BurnerWallet />, document.getElementById('root'));
